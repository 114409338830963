import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-start mb-10" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start" }
const _hoisted_3 = { class: "d-flex align-items-center mb-2" }
const _hoisted_4 = { class: "symbol symbol-35px symbol-circle" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "symbol-label fs-6 fw-bolder bg-light-red text-red"
}
const _hoisted_7 = {
  key: 2,
  class: "symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"
}
const _hoisted_8 = { class: "ms-3" }
const _hoisted_9 = {
  href: "#",
  class: "fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
}
const _hoisted_10 = { class: "text-muted fs-7 mb-1" }
const _hoisted_11 = {
  key: 0,
  class: "p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start",
  "data-kt-element": "message-text"
}
const _hoisted_12 = {
  key: 1,
  class: "demo-image__preview"
}
const _hoisted_13 = { style: {"text-align":"center"} }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.image,
                alt: ""
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.name.charAt(0)), 1)),
          true
            ? (_openBlock(), _createElementBlock("div", _hoisted_7))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.name), 1),
          _createElementVNode("span", _hoisted_10, _toDisplayString(new Date(_ctx.time).toLocaleString()), 1)
        ])
      ]),
      (_ctx.type === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true),
      (_ctx.type === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_el_image, {
              style: {"width":"300px","height":"300px"},
              src: _ctx.api_url + _ctx.text,
              "preview-src-list": _ctx.srcList,
              "initial-index": 0,
              fit: "cover"
            }, null, 8, ["src", "preview-src-list"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.type === 3 || _ctx.type === 4)
        ? (_openBlock(), _createBlock(_component_el_card, {
            key: 2,
            shadow: "hover",
            style: {"width":"150px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, " Archive " + _toDisplayString((_ctx.api_url + _ctx.text)
              .substring((_ctx.api_url + _ctx.text).lastIndexOf("/") + 1)
              .split(".")
              .pop()), 1),
              _createElementVNode("a", {
                href: _ctx.api_url + _ctx.text,
                target: "_blank",
                class: "btn btn-custom btn-app-custom"
              }, "Descargar archivo", 8, _hoisted_14)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}