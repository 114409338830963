
import { defineComponent } from "vue";

export default defineComponent({
  name: "message-out",
  props: {
    image: String,
    time: String,
    text: {
      type: String,
      default: "",
    },
    name: String,
    type: Number,
  },
  mounted() {
    this.srcList = [this.api_url + this.$props.text];
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      srcList: [] as any,
    };
  },
});
